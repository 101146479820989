/* eslint-disable sonarjs/no-duplicate-string */
import {
  Build as BuildIcon,
  DateRange as DateRangeIcon,
  CleaningServicesOutlined as CleaningServicesOutlinedIcon,
} from '@mui/icons-material';
import { Box, styled, svgIconClasses } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as PlaneSVG } from '../../assets/plane.svg';
import { ReactComponent as PlaneOnFlySVG } from '../../assets/planeOnFly.svg';
import { EIGHT_HOURS, ORGAN_COLORS, SIX_HOURS } from '../../const/const';
import { getCurrentEvent, getEventTime } from '../../helpers/aircrafts';
import {
  AircraftCardItem,
  AircraftEvent,
  EAircraftEventType,
  AirportType,
} from '../../types/new';

const STATUS_MAP: Record<EAircraftEventType, string> = {
  [EAircraftEventType.OnTrip]: 'On Trip',
  [EAircraftEventType.OnDuty]: 'Available',
  [EAircraftEventType.OffDuty]: 'Off Duty',
  [EAircraftEventType.OnMaintenance]: 'Maint',
  [EAircraftEventType.NotAvailable]: 'Not Available',
};

const EVENT_TYPE_MAP: Record<EAircraftEventType, string> = {
  [EAircraftEventType.OnTrip]: '',
  [EAircraftEventType.OnDuty]: 'On Duty for',
  [EAircraftEventType.OffDuty]: 'On Rest for',
  [EAircraftEventType.OnMaintenance]: 'Maintenance',
  [EAircraftEventType.NotAvailable]: 'N/A for',
};

const getDutyColor = (timeOnDuty: number): string => {
  if (timeOnDuty >= EIGHT_HOURS) {
    return '#FFFFFF';
  }

  if (timeOnDuty >= SIX_HOURS && timeOnDuty < EIGHT_HOURS) {
    return '#8BD8AE';
  }

  if (timeOnDuty > 0 && timeOnDuty < SIX_HOURS) {
    return '#7591AD';
  }

  return '#7F8083';
};

const PREFIX = 'TSMED-AircraftCard';

const aircraftCardClasses = {
  topBlock: `${PREFIX}-topBlock`,
  timer: `${PREFIX}-timer`,
  status: `${PREFIX}-status`,
  iconBlock: `${PREFIX}-iconBlock`,
  eventsBlock: `${PREFIX}-eventsBlock`,
  event: `${PREFIX}-event`,
  eventName: `${PREFIX}-eventName`,
  eventTimer: `${PREFIX}-eventTimer`,
  icon: `${PREFIX}-icon`,
  registration: `${PREFIX}-registration`,
  dutyIcon: `${PREFIX}-dutyIcon`,
  airportsBlock: `${PREFIX}-airportsBlock`,
  airports: `${PREFIX}-airports`,
  airportName: `${PREFIX}-airportName`,
  airportText: `${PREFIX}-airportText`,
  line: `${PREFIX}-line`,
  onMaintenance: `${PREFIX}-onMaintenance`,
};

const AircraftCardStyled = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { palette } }) => ({
  minHeight: 343,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4,
  border: '1px solid #7f8083',
  padding: 16,
  boxSizing: 'border-box',
  flex: '1 0 auto',
  [`&.${aircraftCardClasses.onMaintenance}`]: {
    borderColor: '#7F8083',
    color: '#7F8083',
    '&.MaintenanceType-AOG': {
      borderColor: '#F88',
      color: '#F88',
    },
  },
  [`.${aircraftCardClasses.topBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },

  [`.${aircraftCardClasses.timer},.${aircraftCardClasses.status}`]: {
    fontSize: 24,
    fontWeight: 500,
  },

  [`.${aircraftCardClasses.status}`]: {
    fontFamily: "'JetBrains Mono', monospace",
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  [`.${aircraftCardClasses.iconBlock}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },

  [`.${aircraftCardClasses.eventsBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 8,
    margin: '8px 0',
  },

  [`.${aircraftCardClasses.event}`]: {
    fontFamily: "'JetBrains Mono', monospace",
    fontSize: 24,
    fontWeight: 500,
    display: 'flex',
    gap: 6,
    height: 36,
  },

  [`.${aircraftCardClasses.eventName}`]: {
    color: 'currentColor',
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    padding: '2px 8px',
    boxSizing: 'border-box',
  },

  [`.${aircraftCardClasses.eventTimer}`]: {
    fontFeatureSettings: "'zero' 1",
    display: 'flex',
    alignItems: 'center',
    color: 'currentColor',
    flexShrink: 0,
    padding: '2px 8px',
    boxSizing: 'border-box',
  },

  [`.${aircraftCardClasses.icon}`]: {
    width: 47,
    height: 47,
  },

  [`.${aircraftCardClasses.registration}`]: {
    fontFamily: "'JetBrains Mono', monospace",
    fontFeatureSettings: "'zero' 1",
    fontSize: 28,
    fontWeight: 500,
    display: 'block',
  },

  [`.${aircraftCardClasses.dutyIcon}`]: {
    width: 50,
    height: 31,
    borderRadius: '4px 0 4px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    backgroundColor: '#7f808326',
    [`.${svgIconClasses.root}`]: {
      color: 'inherit',
    },
  },

  [`.${aircraftCardClasses.airportsBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    color: '#7f8083',
  },

  [`.${aircraftCardClasses.airports}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`.${aircraftCardClasses.airportName}`]: {
    fontSize: 20,
    fontWeight: 500,
    justifyContent: 'space-between',
    display: 'flex',
  },

  [`.${aircraftCardClasses.airportText}`]: {
    fontSize: 24,
    fontWeight: 500,
  },

  [`.${aircraftCardClasses.line}`]: {
    width: '100%',
    border: 0,
    borderBottom: '2px dashed #7f8083',
  },
}));
const getAirportCityState = (airport: AirportType): string => {
  if (!airport) {
    return '';
  }
  if (airport.ServedCityArea) {
    return airport.ServedCityArea.Code;
  }

  return (
    airport.Country?.Name.split(' ')
      .map(i => i[0].toUpperCase())
      .join('') ?? ''
  );
};

const EventsList = ({
  events,
  currentEvent,
  isReturnToBase = false,
}: {
  events: AircraftEvent[];
  currentEvent: AircraftEvent;
  isReturnToBase?: boolean;
}) => (
  <div className={aircraftCardClasses.eventsBlock}>
    {events.map((event, index) => {
      let color = '';

      if (event.Type === EAircraftEventType.OnDuty) {
        const isCurrentEvent = event.Id === currentEvent.Id;
        const eventTime = isCurrentEvent ? getEventTime(event.EventTimeMs) : 0;
        color = getDutyColor(eventTime);
      }

      if (event.Type === EAircraftEventType.OnTrip) {
        const onFlyColor =
          event.Organ && !isReturnToBase
            ? ORGAN_COLORS[event.Organ.name]
            : '#7F8083';
        color = event.IsLate ? '#FF3844' : onFlyColor;
      }

      if (
        [
          EAircraftEventType.OffDuty,
          EAircraftEventType.NotAvailable,
          EAircraftEventType.OnMaintenance,
        ].includes(event.Type)
      ) {
        color = getDutyColor(0);
      }

      const backgroundColor = `${color}26`;

      if (index) {
        color = getDutyColor(0);
      }

      return (
        <div
          key={event.Id}
          className={aircraftCardClasses.event}
          style={{ color }}
        >
          <div
            className={aircraftCardClasses.eventName}
            style={{ backgroundColor }}
          >
            {event.Label ?? EVENT_TYPE_MAP[event.Type]}
          </div>
          <div
            className={aircraftCardClasses.eventTimer}
            style={{ backgroundColor }}
          >
            {event.EventTimeString}
          </div>
        </div>
      );
    })}
  </div>
);
// eslint-disable-next-line complexity
export const AircraftCard = (props: AircraftCardItem & { count: number }) => {
  const { Registration, Events, count } = props;
  const currentEvent = getCurrentEvent(Events);
  const width = `calc(100% / ${Math.ceil(count / 3)} - 10px)`;

  if (props.Type === 'OnGround') {
    const { Airport } = props;
    const timeOnDutyMs =
      currentEvent.Type === EAircraftEventType.OnDuty
        ? getEventTime(currentEvent.EventTimeMs)
        : 0;

    return (
      <AircraftCardStyled
        style={{
          borderColor: getDutyColor(timeOnDutyMs),
          color: getDutyColor(timeOnDutyMs),
          width,
        }}
      >
        <div className={aircraftCardClasses.topBlock}>
          <span className={aircraftCardClasses.status}>
            {STATUS_MAP[currentEvent.Type]}
          </span>
        </div>
        <div className={aircraftCardClasses.iconBlock}>
          <div className={aircraftCardClasses.icon}>
            <PlaneSVG />
          </div>
          <span className={aircraftCardClasses.registration}>
            {Registration}
          </span>
          <div
            className={aircraftCardClasses.dutyIcon}
            style={{
              backgroundColor: `${getDutyColor(timeOnDutyMs)}26`,
            }}
          >
            {timeOnDutyMs >= EIGHT_HOURS && <>8+</>}
            {timeOnDutyMs >= SIX_HOURS && timeOnDutyMs < EIGHT_HOURS && (
              <>6-8</>
            )}
            {timeOnDutyMs > 0 && timeOnDutyMs < SIX_HOURS && <>{`<6`}</>}
            {!timeOnDutyMs && <>X</>}
          </div>
        </div>
        <EventsList events={Events} currentEvent={currentEvent} />
        <div className={aircraftCardClasses.airportsBlock}>
          {Airport ? (
            <span className={aircraftCardClasses.airportName}>{`${
              Airport.ServedCity
            }, ${getAirportCityState(Airport)}`}</span>
          ) : (
            <span className={aircraftCardClasses.airportName}>----</span>
          )}
          <div className={aircraftCardClasses.airports}>
            <span className={aircraftCardClasses.airportText}>
              {Airport ? Airport.IATA || Airport.ICAO : '----'}
            </span>
            <span className={aircraftCardClasses.airportText}>----</span>
          </div>
        </div>
      </AircraftCardStyled>
    );
  }

  if (props.Type === 'OnMaintenance' && currentEvent.Type === 'OnMaintenance') {
    const { Airport } = props;
    return (
      <AircraftCardStyled
        style={{
          width,
        }}
        className={classNames(aircraftCardClasses.onMaintenance, {
          [`MaintenanceType-${currentEvent.MaintenanceType}`]: Boolean(
            currentEvent.MaintenanceType,
          ),
        })}
      >
        <div className={aircraftCardClasses.topBlock}>
          {currentEvent?.MaintenanceType === 'CLN' ? (
            <span className={aircraftCardClasses.status}>Cleaning</span>
          ) : (
            <span className={aircraftCardClasses.status}>{`${
              STATUS_MAP[currentEvent.Type]
            }${
              currentEvent.MaintenanceType &&
              `: ${currentEvent.MaintenanceType}`
            }
          `}</span>
          )}
        </div>
        <div className={aircraftCardClasses.iconBlock}>
          <div className={aircraftCardClasses.icon}>
            <PlaneSVG />
          </div>
          <span className={aircraftCardClasses.registration}>
            {Registration}
          </span>
          <div className={aircraftCardClasses.dutyIcon}>
            {currentEvent.MaintenanceType === 'CLN' ? (
              <CleaningServicesOutlinedIcon />
            ) : (
              (currentEvent.isActual && <BuildIcon />) || <DateRangeIcon />
            )}
          </div>
        </div>
        <EventsList events={Events} currentEvent={currentEvent} />
        <div className={aircraftCardClasses.airportsBlock}>
          {Airport ? (
            <span className={aircraftCardClasses.airportName}>{`${
              Airport.ServedCity
            }, ${getAirportCityState(Airport)}`}</span>
          ) : (
            <span className={aircraftCardClasses.airportName}>----</span>
          )}
          <div className={aircraftCardClasses.airports}>
            <span className={aircraftCardClasses.airportText}>
              {Airport ? Airport.IATA || Airport.ICAO : '----'}
            </span>
            <span className={aircraftCardClasses.airportText}>----</span>
          </div>
        </div>
      </AircraftCardStyled>
    );
  }

  if (props.Type === 'OnFly') {
    const { Airports, IsOnFly, IsOnCase, IsReturnToBase, Status } = props;

    const ArrivalAirportIndex = Airports.findIndex(i => !!i?.IsNextArrival);
    const ArrivalAirport = Airports[ArrivalAirportIndex];
    const DepartureAirport = Airports[ArrivalAirportIndex - 1];

    const onFlyColor =
      currentEvent.Organ && !IsReturnToBase
        ? ORGAN_COLORS[currentEvent.Organ.name]
        : '#7F8083';
    const color = currentEvent.IsLate ? '#FF3844' : onFlyColor;

    return (
      <AircraftCardStyled
        style={{
          borderColor: color,
          color,
          width,
        }}
      >
        <div className={aircraftCardClasses.topBlock}>
          <span className={aircraftCardClasses.status}>
            {STATUS_MAP[currentEvent.Type]}
          </span>
        </div>
        <div className={aircraftCardClasses.iconBlock}>
          <div className={aircraftCardClasses.icon}>
            {IsOnFly ? <PlaneOnFlySVG /> : <PlaneSVG />}
          </div>
          <span className={aircraftCardClasses.registration}>
            {Registration}
          </span>
          {!IsOnCase && <div className={aircraftCardClasses.dutyIcon}>X</div>}
        </div>
        <EventsList
          events={Events}
          isReturnToBase={IsReturnToBase}
          currentEvent={currentEvent}
        />
        <div className={aircraftCardClasses.airportsBlock}>
          <span className={aircraftCardClasses.airportName}>
            {!IsOnFly && DepartureAirport
              ? `${DepartureAirport.ServedCity}, ${getAirportCityState(
                  DepartureAirport,
                )}`
              : Status}
          </span>
          <div className={aircraftCardClasses.airports}>
            <span className={aircraftCardClasses.airportText}>
              {DepartureAirport
                ? DepartureAirport.IATA || DepartureAirport.ICAO
                : '----'}
            </span>
            <span className={aircraftCardClasses.airportText}>
              {ArrivalAirport
                ? ArrivalAirport.IATA || ArrivalAirport.ICAO
                : '----'}
            </span>
          </div>
        </div>
      </AircraftCardStyled>
    );
  }

  return null;
};
