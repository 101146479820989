import classnames from 'classnames';
import { useEffect, useState } from 'react';

import {
  AIRCRAFTS_MAP_CLUSTER_OFFSET,
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
} from '../../../const/const';
import { getAircraftPosition } from '../../../helpers/pathGeneration';
import {
  AircraftClusterTypes,
  AircraftOnAirportType,
  CaseClusterType,
  ClusterData,
  ClusterDataEnum,
  ClusterItem,
} from '../../../types/new';
import styles from '../overlays.module.scss';

import { AircraftOnFlyIcons } from './AircraftOnFlyIcons';
import { AircraftOnGroundIcons } from './AircraftOnGroundIcons';
import { AircraftOnMaintenanceIcons } from './AircraftOnMaintenanceIcons';
import { CaseItemIcons } from './CaseItemIcons';
import { CaseOrgan } from './CaseOrgan';

interface ClusterComponentProps<T> extends Pick<ClusterItem<T>, 'items'> {
  clientWidth: number;
  clientHeight: number;
  isCluster: boolean;
}

const CasesCluster = ({
  items,
  clientWidth,
  clientHeight,
  isCluster,
}: ClusterComponentProps<CaseClusterType>) => {
  const [position, setPosition] = useState<Record<string, number> | null>(null);

  useEffect(() => {
    const item = items[0];

    setPosition({
      x: item.location[0],
      y: item.location[1],
    });
  }, [items]);

  return (
    <>
      {items.map((i, index) => {
        if (i.data.showOrgan) {
          return (
            <CaseOrgan
              key={i.data.id}
              {...i.data}
              organTopOffsetIndex={i.data.viewData?.organTopOffset}
              clientWidth={clientWidth}
              clientHeight={clientHeight}
              zIndex={items.length - index}
            />
          );
        }

        return null;
      })}
      {!!position && (
        <div
          className={classnames(styles.absolutePosition)}
          style={{ top: position.y, left: position.x }}
        >
          <div className={styles.itemsList}>
            {items.map(item => (
              <CaseItemIcons
                key={item.data.id}
                {...item.data}
                organ={item.data.organ}
                isSetElementRef={isCluster}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const AircraftsCluster = ({
  items,
  clientWidth,
  clientHeight,
  isCluster,
}: ClusterComponentProps<AircraftClusterTypes>) => {
  const [position, setPosition] = useState<Record<string, number> | null>(null);

  useEffect(() => {
    const item = items[0];
    const lng =
      item.data.Location?.Longitude ??
      (item.data as AircraftOnAirportType).Airport?.Longitude ??
      null;
    const lat =
      item.data.Location?.Latitude ??
      (item.data as AircraftOnAirportType).Airport?.Latitude ??
      null;
    const boundary =
      item.data.Location?.Boundary ??
      (item.data as AircraftOnAirportType).Airport?.Boundary ??
      null;
    if (lng && lat && boundary) {
      let leg;

      if (item.type === ClusterDataEnum.OnFly) {
        const arrivalAirportIndex = item.data.Airports.findIndex(
          i => !!i?.IsNextArrival,
        );
        leg = {
          start: item.data.Airports[arrivalAirportIndex - 1],
          end: item.data.Airports[arrivalAirportIndex],
          startTime: item.data.TripLegStart as string,
          endTime: item.data.TripLegEnd as string,
        };
      }

      const coordinates = getAircraftPosition({
        coordinates: [lng, lat],
        boundary,
        leg,
      });

      if (coordinates) {
        const theBiggestItemWidth = items.reduce(
          (prev, current) => (prev > current.size[0] ? prev : current.size[0]),
          0,
        );
        const itemHeight = items.reduce(
          (prev, current) => prev + current.size[1],
          0,
        );
        const positionX =
          (coordinates[0] / MAP_OVERLAY_VIEW_WIDTH) * clientWidth;
        const positionY =
          (coordinates[1] / MAP_OVERLAY_VIEW_HEIGHT) * clientHeight;

        const itemEndXPosition = positionX + theBiggestItemWidth;
        const itemEndYPosition = positionY + itemHeight;
        const clientLeftBorder = clientWidth + AIRCRAFTS_MAP_CLUSTER_OFFSET;
        const clientBottomBorder = clientHeight;
        const itemLeftStickyPosition = clientLeftBorder - theBiggestItemWidth;
        const itemBottomStickyPosition = clientBottomBorder - itemHeight;
        const itemXPosition =
          itemEndXPosition >= clientLeftBorder
            ? itemLeftStickyPosition
            : positionX;
        const itemYPosition =
          itemEndYPosition >= clientBottomBorder
            ? itemBottomStickyPosition
            : positionY;

        setPosition({
          x: itemXPosition,
          y: itemYPosition,
        });
      }
    }
  }, [items, clientWidth, clientHeight]);

  const isFirstItemWithLabel =
    items[0].type === ClusterDataEnum.OnFly ? !!items[0].data.Label : false;

  return (
    <>
      {position && (
        <div
          className={classnames(
            styles.absolutePosition,
            styles.aircraftMapPosition,
            {
              [styles.aircraftMapPositionWithLabel]: isFirstItemWithLabel,
            },
          )}
          style={{ top: position.y, left: position.x }}
        >
          <div className={classnames(styles.itemsList, styles.aircraftsList)}>
            {items.map(item => {
              if (item.type === ClusterDataEnum.OnFly) {
                return (
                  <AircraftOnFlyIcons
                    key={item.data.Id}
                    Id={item.data.Id}
                    Registration={item.data.Registration}
                    Direction={item.data.Direction}
                    Organ={item.data.Organ}
                    IsLate={item.data.IsLate}
                    Label={item.data.Label}
                    IsOnFly={item.data.IsOnFly}
                    IsOnCase={item.data.IsOnCase}
                    IsReturnToBase={item.data.IsReturnToBase}
                    Airports={item.data.Airports}
                    isSetElementRef={isCluster}
                  />
                );
              }

              return null;
            })}
            {items.map(item => {
              if (
                item.type === ClusterDataEnum.OnGround &&
                item.data.Type !== 'OnMaintenance'
              ) {
                return (
                  <AircraftOnGroundIcons
                    key={item.data.Id}
                    isSetElementRef={isCluster}
                    isActual={item.data.isActual}
                    {...item.data}
                  />
                );
              }

              return null;
            })}
            {items.map(item => {
              if (
                item.type === ClusterDataEnum.OnGround &&
                item.data.Type === 'OnMaintenance'
              ) {
                return (
                  <AircraftOnMaintenanceIcons
                    key={item.data.Id}
                    isSetElementRef={isCluster}
                    isActual={item.data.isActual}
                    {...item.data}
                  />
                );
              }

              return null;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export const ClusterComponent = <
  T extends CaseClusterType | AircraftClusterTypes,
>({
  items,
  clientWidth,
  clientHeight,
  isCluster,
}: ClusterComponentProps<T>) => {
  const [clusterType, setClusterType] = useState<ClusterDataEnum | null>(null);

  useEffect(() => {
    const item = items[0];

    setClusterType(item.type);
  }, [items]);

  if (clusterType === ClusterDataEnum.Case) {
    return (
      <CasesCluster
        items={items as ClusterData<CaseClusterType>[]}
        clientWidth={clientWidth}
        clientHeight={clientHeight}
        isCluster={isCluster}
      />
    );
  }

  if (
    clusterType === ClusterDataEnum.OnGround ||
    clusterType === ClusterDataEnum.OnFly
  ) {
    return (
      <AircraftsCluster
        items={items as ClusterData<AircraftClusterTypes>[]}
        clientWidth={clientWidth}
        clientHeight={clientHeight}
        isCluster={isCluster}
      />
    );
  }

  return null;
};
