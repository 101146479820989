import { getCurrentEvent, getEventTime } from '../../helpers/aircrafts';
import {
  AircraftCardItem,
  AircraftEvent,
  EAircraftEventType,
  TAircraftList,
  AircraftStatus,
  CaseType,
} from '../../types/new';

const findActiveCase = (
  cases: CaseType[],
  item: AircraftEvent,
): CaseType | null => {
  if (item.Type === EAircraftEventType.OnTrip && item.Label) {
    return (
      cases.find(i => {
        const externalReference = item.Label?.toLowerCase() ?? '';
        const unosId = i.label.substring(0, 7);
        const organFull = i.organ.name;
        const organShort =
          organFull === 'heart' ? 'ht|hr' : organFull.substring(0, 2);

        if (externalReference.includes(unosId.toLowerCase())) {
          const reference = externalReference.split(unosId.toLowerCase())[1];

          const organFullRegex = new RegExp(organFull.toLowerCase(), 'gm');
          const organShortRegex = new RegExp(organShort, 'gm');

          return (
            !!RegExp(organFullRegex).exec(reference) ||
            !!RegExp(organShortRegex).exec(reference)
          );
        }

        return false;
      }) || null
    );
  }

  return null;
};

export const transformAircrafts = (
  data: AircraftStatus[],
  cases: CaseType[] | null = null,
): TAircraftList =>
  data.reduce<TAircraftList>((acc, item) => {
    const currentEvent = getCurrentEvent(item.Events);

    if (item.Type === 'OnMaintenance') {
      const onDutyTime =
        currentEvent?.Type === EAircraftEventType.OnDuty
          ? getEventTime(currentEvent.EventTimeMs)
          : 0;

      return [
        ...acc,
        {
          Id: `aircraft-${item.Id}`,
          Registration: item.Registration,
          Airport: item.Airport,
          TimeOnDuty: onDutyTime,
          Type: item.Type,
          MaintenanceType: currentEvent.MaintenanceType,
          MaintenanceName: currentEvent.MaintenanceName,
          isActual: currentEvent.isActual,
        },
      ];
    }

    if (
      item.Type === 'OnGround' &&
      item.Location?.Longitude &&
      item.Location?.Latitude
    ) {
      const onDutyTime =
        currentEvent?.Type === EAircraftEventType.OnDuty
          ? getEventTime(currentEvent.EventTimeMs)
          : 0;

      return [
        ...acc,
        {
          Id: `aircraft-${item.Id}`,
          Registration: item.Registration,
          Location: item.Location,
          Airport: item.Airport,
          TimeOnDuty: onDutyTime,
          Type: item.Type,
        },
      ];
    }

    if (
      item.Type === 'OnFly' &&
      item.Location?.Latitude &&
      item.Location?.Longitude
    ) {
      const showOnFlyAircraft = !!cases || item.IsReturnToBase;
      const currentCase =
        item.IsOnCase && cases ? findActiveCase(cases, currentEvent) : null;

      if (showOnFlyAircraft) {
        return [
          ...acc,
          {
            Id: `aircraft-${item.Id}`,
            Registration: item.Registration,
            IsOnFly: item.IsOnFly,
            Location: item.Location,
            Direction: item.Direction,
            Airports: item.Airports,
            IsReturnToBase: item.IsReturnToBase,
            TripLegStart: item.TripLegStart,
            TripLegEnd: item.TripLegEnd,
            IsOnCase: item.IsOnCase,
            Label: currentCase?.label,
            Organ: currentCase?.organ.name,
            IsLate: currentCase?.cardStatus === 'warning',
            Type: item.Type,
          },
        ];
      }
    }

    return acc;
  }, []);

export const setCasesToAircrafts = (
  aircrafts: AircraftStatus[],
  cases: CaseType[],
): AircraftCardItem[] =>
  aircrafts
    .map<AircraftCardItem>(item => {
      const currentEvents = item.Events.map(item => {
        if (item.Type === EAircraftEventType.OnTrip) {
          const currentCase = findActiveCase(cases, item);

          if (currentCase) {
            return {
              ...item,
              Label: currentCase.label,
              Organ: currentCase.organ,
              IsLate: currentCase.cardStatus === 'warning',
            };
          }
        }

        return item;
      });

      return {
        ...item,
        Events: currentEvents,
      };
    })
    .sort((a, b) => {
      if (a.Type === 'OnMaintenance') {
        return 1;
      }
      if (b.Type === 'OnMaintenance') {
        return -1;
      }
      if (a.Id > b.Id) {
        return 1;
      }
      return -1;
    });
