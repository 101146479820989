import bbox from '@turf/bbox';
import buffer from '@turf/buffer';
import { BBox, point } from '@turf/helpers';
import { v4 as uuid4 } from 'uuid';

import {
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
} from '../const/const';
import {
  AircraftClusterTypes,
  AircraftOnAirportType,
  AircraftOnFlyType,
  CaseBboxArrayItem,
  CaseClusterType,
  CaseType,
  ClusterBboxItem,
  ClusterDataEnum,
  ClusterItem,
  TPoint,
} from '../types/new';

import { inBBox, isBboxIntersected } from './bbox';
import {
  getAircraftPosition,
  getCurve,
  getPointPixelsCoordinates,
} from './pathGeneration';

type CaseClusteringProps = {
  cases: CaseType[];
  clientWidth: number;
  clientHeight: number;
};

type AircraftClusteringProps = {
  aircrafts: (AircraftOnAirportType | AircraftOnFlyType)[];
  clientWidth: number;
  clientHeight: number;
};

type GenerateBboxFuncProps = {
  location: { x: number; y: number };
  radius: { x: number; yTop: number; yBottom?: number };
  clientSize: { width: number; height: number };
};

const MAP_X_LEFT_RADIUS = 5;

const MAIN_MAP_Y_RADIUS_PX = 19;
const MAIN_MAP_X_RIGHT_RADIUS_PX = 136;

export const AIRCRAFTS_MAP_Y_RADIUS_PX = 35;
const AIRCRAFTS_LABEL_MAP_Y_RADIUS_PX = 56;
const AIRCRAFT_LABEL_LEFT_OFFSET = 20;

const calculateAircraftItemWidth = (item: AircraftClusterTypes): number => {
  const CHAR_WIDTH = 16.8;
  const TAIL_NUMBER_GAP = 8;
  const ICON_WIDTH = 37;
  const ICON_GAP = 4;
  const DUTY_BLOCK_WIDTH = 50;

  if (item.type === ClusterDataEnum.OnGround) {
    const tailWidth = item.data.Registration.length * CHAR_WIDTH;
    const airportWidth = (item.data.Airport?.IATA?.length || 0) * CHAR_WIDTH;
    const iconWithDutyWidth = ICON_WIDTH + ICON_GAP + DUTY_BLOCK_WIDTH;

    return (
      AIRCRAFT_LABEL_LEFT_OFFSET +
      tailWidth +
      TAIL_NUMBER_GAP +
      airportWidth +
      ICON_GAP +
      iconWithDutyWidth
    );
  }

  if (item.type === ClusterDataEnum.OnFly) {
    const tailWidth = item.data.Registration.length * CHAR_WIDTH;
    const airportsWidth = item.data.Airports.length * 3 * CHAR_WIDTH;
    const dotsWidth = (item.data.Airports.length - 2) * CHAR_WIDTH;
    const airportsInfoWidth =
      airportsWidth + dotsWidth + ICON_WIDTH + ICON_GAP * 2;
    const dutyWidth = !item.data.IsOnCase ? ICON_GAP + DUTY_BLOCK_WIDTH : 0;

    return (
      AIRCRAFT_LABEL_LEFT_OFFSET +
      tailWidth +
      TAIL_NUMBER_GAP +
      airportsInfoWidth +
      dutyWidth
    );
  }

  return 0;
};

export const getClusterCoordinatesBbox = (
  id: string,
  [lon1, lat1]: number[],
  [lon2, lat2]: number[],
  bboxArray: [string, CaseBboxArrayItem][],
): [string, CaseBboxArrayItem] => {
  const coordsArray = [
    [lon1, lat1],
    [lon2, lat2],
  ].sort((a, b) => (a[0] > b[0] ? -1 : 1));
  if (bboxArray.length) {
    const bboxItem = bboxArray.find(item =>
      coordsArray.every((coords, index) =>
        inBBox(coords, item[1].bBoxArray[index]),
      ),
    );

    if (bboxItem) {
      return bboxItem;
    }
  }

  const newBboxArrayItems = coordsArray.map(coords => {
    const searchPoint = point(coords);

    const bufferPoint = buffer(searchPoint, 50, { units: 'kilometers' });
    return bbox(bufferPoint);
  });

  return [
    id,
    {
      key: id,
      bBoxArray: newBboxArrayItems,
      items: [],
    },
  ];
};

const getProgressPixels = (
  curve: string,
  progress: number,
  clientWidth: number,
  clientHeight: number,
): number[] | null => {
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', curve);

  const pathLength = path.getTotalLength();

  const { x, y } = path.getPointAtLength(pathLength * (progress / 100));

  return [
    (x / MAP_OVERLAY_VIEW_WIDTH) * clientWidth,
    (y / MAP_OVERLAY_VIEW_HEIGHT) * clientHeight,
  ];
};

const generateBbox = ({
  location,
  radius,
  clientSize,
}: GenerateBboxFuncProps): BBox => {
  const { x, y } = location;
  const { x: xRadius, yTop: yRadiusTop, yBottom: yRadiusBottom = 0 } = radius;
  const { width: clientWidth, height: clientHeight } = clientSize;

  const rightBboxEnd = x + xRadius;
  const bottomBboxEnd = y + yRadiusBottom;

  const pointX =
    rightBboxEnd > clientWidth ? x - (rightBboxEnd - clientWidth) : x;

  const pointY =
    bottomBboxEnd > clientHeight ? y - (bottomBboxEnd - clientHeight) : y;

  return [
    pointX - MAP_X_LEFT_RADIUS,
    pointY - yRadiusTop,
    pointX + xRadius,
    pointY + yRadiusBottom,
  ];
};

export const getCasesPath = (cases: CaseType[]): CaseType[] => {
  const bboxMap: Map<string, CaseBboxArrayItem> = new Map();
  const organsMap: Map<string, { items: CaseType[]; location: TPoint }> =
    new Map();

  return cases.map(item => {
    const { donorHospital, recipientHospital } = item;

    if (!donorHospital.boundary || !recipientHospital.boundary) {
      return item;
    }

    if (!donorHospital.lonLat || !recipientHospital.lonLat) {
      return item;
    }

    let organTopOffset = 0;

    if (item.showOrgan) {
      const keyLocation = !item.mapProgress
        ? donorHospital.lonLat
        : recipientHospital.lonLat;
      const mapKey = keyLocation.toString();
      const caseList = organsMap.get(mapKey);

      if (caseList) {
        organsMap.set(mapKey, {
          items: [...caseList.items, item],
          location: caseList.location,
        });

        organTopOffset = caseList.items.length;
      } else {
        organsMap.set(mapKey, {
          items: [item],
          location: keyLocation,
        });
      }
    }

    const bboxArray = Array.from(bboxMap.entries());

    const [id, bbox] = getClusterCoordinatesBbox(
      item.id,
      donorHospital.lonLat,
      recipientHospital.lonLat,
      bboxArray,
    );

    const [xA, yA] = getPointPixelsCoordinates(
      donorHospital.lonLat,
      donorHospital.boundary,
    );
    const [xB, yB] = getPointPixelsCoordinates(
      recipientHospital.lonLat,
      recipientHospital.boundary,
    );

    if (id !== item.id) {
      const bboxItems = [...bbox.items, item.id];

      bboxMap.set(id, {
        ...bbox,
        items: bboxItems,
      });

      return {
        ...item,
        viewData: {
          pointA: [xA, yA],
          pointB: [xB, yB],
          curve: getCurve([xA, yA], [xB, yB], bboxItems.length),
          organTopOffset,
        },
      };
    }

    bboxMap.set(id, {
      ...bbox,
      items: [item.id],
    });

    return {
      ...item,
      viewData: {
        pointA: [xA, yA],
        pointB: [xB, yB],
        curve: getCurve([xA, yA], [xB, yB]),
        organTopOffset,
      },
    };
  });
};

const hasOverlappedItems = <T>(items: ClusterBboxItem<T>[]): boolean =>
  items.some(
    (item, _, array) =>
      !!array.find(i => {
        if (i.key !== item.key) {
          return isBboxIntersected(i.bBox, item.bBox);
        }
        return false;
      }),
  );

const mergeClusters = <T>(
  items: ClusterBboxItem<T>[],
  clientWidth: number,
  clientHeight: number,
): ClusterBboxItem<T>[] => {
  const clusters = items.reduce<ClusterBboxItem<T>[]>((acc, item) => {
    const overlappedCluster = acc.find(i =>
      isBboxIntersected(i.bBox, item.bBox),
    );

    if (overlappedCluster) {
      return acc.map(i => {
        if (overlappedCluster.key === i.key) {
          const mergedItems = i.items
            .concat(item.items)
            .sort((a, b) => (a.location[0] > b.location[0] ? -1 : 1));
          const newClusterLocation = mergedItems[0].location;
          const itemXsize = mergedItems[0].size[0];
          const itemYsize = mergedItems[0].size[1];
          const mergedItemsHeight = mergedItems.reduce(
            (acc, m) => acc + m.size[1],
            -itemYsize,
          );

          return {
            ...i,
            clusterLocation: newClusterLocation,
            bBox: generateBbox({
              location: { x: newClusterLocation[0], y: newClusterLocation[1] },
              radius: {
                x: itemXsize,
                yTop: itemYsize,
                yBottom: mergedItemsHeight,
              },
              clientSize: { width: clientWidth, height: clientHeight },
            }),
            items: mergedItems,
          };
        }

        return i;
      });
    }

    return [...acc, item];
  }, []);

  const hasOverlapping = hasOverlappedItems(clusters);
  if (hasOverlapping) {
    return mergeClusters(clusters, clientWidth, clientHeight);
  }

  return clusters;
};

export const getCaseClustering = ({
  cases,
  clientWidth,
  clientHeight,
}: CaseClusteringProps): ClusterItem<CaseClusterType>[] => {
  const casesList = cases
    .map(item => {
      if (item.viewData?.curve) {
        const progressPixels = getProgressPixels(
          item.viewData.curve,
          item.mapProgress,
          clientWidth,
          clientHeight,
        );

        if (progressPixels) {
          const data = {
            type: ClusterDataEnum.Case,
            data: item,
            location: progressPixels,
            size: [MAIN_MAP_X_RIGHT_RADIUS_PX, MAIN_MAP_Y_RADIUS_PX],
          };

          return {
            key: uuid4(),
            clusterLocation: progressPixels,
            bBox: generateBbox({
              location: { x: progressPixels[0], y: progressPixels[1] },
              radius: {
                x: MAIN_MAP_X_RIGHT_RADIUS_PX,
                yTop: MAIN_MAP_Y_RADIUS_PX,
              },
              clientSize: { width: clientWidth, height: clientHeight },
            }),
            items: [data],
          };
        }
      }

      return null;
    })
    .filter((item): item is ClusterBboxItem<CaseClusterType> => !!item)
    .sort((a, b) => (a.clusterLocation[0] > b.clusterLocation[0] ? -1 : 1));

  const clusters = mergeClusters<CaseClusterType>(
    casesList,
    clientWidth,
    clientHeight,
  );

  return clusters.map<ClusterItem<CaseClusterType>>(item => ({
    key: `cluster-${item.items[0].data.id}`,
    isCluster: item.items.length > 1,
    items: item.items,
  }));
};

export const getAircraftClustering = ({
  aircrafts,
  clientWidth,
  clientHeight,
}: AircraftClusteringProps): ClusterItem<AircraftClusterTypes>[] => {
  const aircraftsList = aircrafts
    .map(item => {
      const lng =
        item.Location?.Longitude ??
        (item as AircraftOnAirportType).Airport?.Longitude;
      const lat =
        item.Location?.Latitude ??
        (item as AircraftOnAirportType).Airport?.Latitude;
      const boundary =
        item.Location?.Boundary ??
        (item as AircraftOnAirportType).Airport?.Boundary;
      if (lng && lat) {
        // cannot use item.hasOwnProperty via eslint rules (https://eslint.org/docs/latest/rules/no-prototype-builtins)
        if (Object.prototype.hasOwnProperty.call(item, 'Direction')) {
          const aircraft = item as AircraftOnFlyType;
          const arrivalAirportIndex = aircraft.Airports.findIndex(
            i => !!i?.IsNextArrival,
          );
          const coordinates = getAircraftPosition({
            coordinates: [lng, lat],
            boundary,
            leg: {
              start: aircraft.Airports[arrivalAirportIndex - 1],
              end: aircraft.Airports[arrivalAirportIndex],
              startTime: aircraft.TripLegStart as string,
              endTime: aircraft.TripLegEnd as string,
            },
          });

          if (!coordinates) {
            return null;
          }

          const convertedX =
            (coordinates[0] / MAP_OVERLAY_VIEW_WIDTH) * clientWidth;
          const convertedY =
            (coordinates[1] / MAP_OVERLAY_VIEW_HEIGHT) * clientHeight;

          const ySize = aircraft.Label
            ? AIRCRAFTS_LABEL_MAP_Y_RADIUS_PX
            : AIRCRAFTS_MAP_Y_RADIUS_PX;
          const itemWidth = calculateAircraftItemWidth({
            type: ClusterDataEnum.OnFly,
            data: aircraft,
          });
          const data = {
            type: ClusterDataEnum.OnFly,
            data: item as AircraftOnFlyType,
            location: [convertedX, convertedY],
            size: [itemWidth, ySize],
          } as AircraftClusterTypes;

          return {
            key: uuid4(),
            clusterLocation: [convertedX, convertedY],
            bBox: generateBbox({
              location: { x: convertedX, y: convertedY },
              radius: { x: itemWidth, yTop: ySize },
              clientSize: { width: clientWidth, height: clientHeight },
            }),
            items: [data],
          };
        }
        const coordinates = getAircraftPosition({
          coordinates: [lng, lat],
          boundary,
        });

        if (!coordinates) {
          return null;
        }

        const convertedX =
          (coordinates[0] / MAP_OVERLAY_VIEW_WIDTH) * clientWidth;
        const convertedY =
          (coordinates[1] / MAP_OVERLAY_VIEW_HEIGHT) * clientHeight;

        const aircraftItem = item as AircraftOnAirportType;
        const itemWidth = calculateAircraftItemWidth({
          type: ClusterDataEnum.OnGround,
          data: aircraftItem,
        });

        const data = {
          type: ClusterDataEnum.OnGround,
          data: aircraftItem,
          location: [convertedX, convertedY],
          size: [itemWidth, AIRCRAFTS_MAP_Y_RADIUS_PX],
        } as AircraftClusterTypes;

        return {
          key: uuid4(),
          clusterLocation: [convertedX, convertedY],
          bBox: generateBbox({
            location: { x: convertedX, y: convertedY },
            radius: { x: itemWidth, yTop: AIRCRAFTS_MAP_Y_RADIUS_PX },
            clientSize: { width: clientWidth, height: clientHeight },
          }),
          items: [data],
        };
      }

      return null;
    })
    .filter((item): item is ClusterBboxItem<AircraftClusterTypes> => !!item)
    .sort((a, b) => (a.clusterLocation[0] > b.clusterLocation[0] ? -1 : 1));

  const clusters = mergeClusters<AircraftClusterTypes>(
    aircraftsList,
    clientWidth,
    clientHeight,
  );

  return clusters.map<ClusterItem<AircraftClusterTypes>>(item => ({
    key: `cluster-${item.items[0].data.Id}`,
    isCluster: item.items.length > 1,
    items: item.items,
  }));
};
